<template>
  <div class="orderList-box">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有数据"
      @load="clickLoad"
    >
      <div v-for="(item, index) in nftOrderList" :key="index">
        <div class="item">
          <div class="dv1">
            <img src="../../assets/images/yuanyuzhou.png">
          </div>
          <div class="box">
            <div class="dv2">
              <div class="left"><span>卡号：</span>{{ item.code }}</div>
              <div class="right">
                <span @click="copy(item.code)">复制</span>
              </div>
            </div>
            <div v-if="item.pwd" class="dv2">
              <div class="left"><span>密码：</span>{{ item.pwd }}</div>
              <div class="right">
                <span @click="copy(item.pwd)">复制</span>
              </div>
            </div>
          </div>
          <div class="order">
            <div class="left">
              <span>订单编号：{{ item.orderNo }}</span>
            </div>
            <div class="right">
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <!-- <div v-if="!nftOrderList.length" class="ordernull">
      <van-empty
        image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
        description="暂无数据"
      />
    </div> -->
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast, Empty, List, DropdownItem, DropdownMenu } from 'vant'
import { fetchnftlis } from '@/services/order'
import { copyText } from '@/utils/common/utils'
Vue.use(Empty).use(List).use(DropdownItem).use(DropdownMenu)
export default {
  data() {
    return {
      nftOrderList: [],
      loading: false,
      finished: false,
      page: 1,
      pageSize: 10,
      total: 0,
      isMoreData: 1
      // text: "暂无数据",
    }
  },
  mounted() {
    const pJsonStr = JSON.stringify({ eventName: 'isShowShare', isShow: 0 })
    if (window.NativeMethod) {
      // 安卓原生
      window.NativeMethod.postMessage(pJsonStr)
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.NativeMethod
    ) {
      // IOS原生方法
      window.webkit.messageHandlers.NativeMethod.postMessage(pJsonStr)
    } else if (window.ReactNativeWebView) {
      // 安卓RN
      window.ReactNativeWebView.postMessage(pJsonStr)
    }
  },
  methods: {
    getData() {
      const mid = this.$route.query.mid
      const prom = {
        page: this.page,
        pageSize: this.pageSize,
        // mid:1330281,
        mid: mid || ''
      }
      fetchnftlis(prom).then((res) => {
        this.loading = false
        if (res.data.list.length) {
          this.nftOrderList.push(...res.data.list)
        } else {
          this.isMoreData = 0//
          this.finished = true // 结束加载状态
        }
      })
    },
    clickLoad() {
      if (this.isMoreData === 1) {
        this.getData()
        this.page++
      }
    },
    // 复制文案
    copy(e) {
      copyText(e, () => {
        Toast('复制成功')
      })
    }
  }
}
</script>
<style scoped>
#app {
  background: #f8f8f8;
}
.orderList-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f8f8f8;
  padding: 10px 14px;
}
.orderList-box /deep/ .mescroll {
  position: static !important;
}
.item {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
  font-size: 12px;
  padding: 5px 9px 14px 18px;
}
.dv1 {
  border-bottom: 1px solid #f8f8f8;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}
.dv1 img {
  width: 78px;
  height: 25px;
}
.box {
  padding-bottom: 17px;
  border-bottom: 1px solid #f8f8f8;
}
.dv2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
}
.dv2 .left span {
  color: #999;
}
.dv2 .right span {
  padding: 3px 13px;
  background: #e53838;
  border-radius: 10px;
  color: #fff;
}
.order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
}
.left {
  flex-shrink: 0;
}
.order span {
  color: #999;
}
</style>
